import Burger from '../../../assets/images/Burger.png'
import Quote from '../../../assets/images/Quote.png'
import PokedexJS from '../../../assets/images/PokedexJS.png'
import PokedexReact from '../../../assets/images/PokedexReact.png'
import Tailwind from '../../../assets/images/tailwind.png'
import Material from '../../../assets/images/material.png'
import Chart from '../../../assets/images/chart.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCss3, faHtml5, faJsSquare, faReact } from '@fortawesome/free-brands-svg-icons'


export const SliderData = [
    {
      image: Burger,
      title: 'Burger Restaurant E-Commerce Shop',
      description: 'Ein E-Commerce Shop für ein Burger-Restaurant',
      technologies: [
        
        { icon: <FontAwesomeIcon icon={faHtml5} color="#F06529" />, name: 'HTML5'},
        { icon: <FontAwesomeIcon icon={faReact} color='#5ED4F4'/>, name: 'React'},
        { icon: <FontAwesomeIcon icon={faCss3} color='#28A4D9' />, name: 'CSS3'},
        { icon: <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />, name: 'Javascript'},
        {
          icon: (
            <img
              src={Tailwind}
              alt="Tailwind CSS"
              style={{ width: '20px', height: 'auto' }} // Adjust the width and height as needed
            />
          ),
          name: 'Tailwind CSS', // Use the <img> tag for the Tailwind logo
        },
        
        // Add more technologies as needed
      ],
      link: 'https://burger-commerce.web.app/'
    },
    {
      image:
        Quote,
        title: 'Random Facts',
        description: 'Lass dir zufällige Fakten anzeigen',
        technologies: [
        
          { icon: <FontAwesomeIcon icon={faHtml5} color="#F06529" />, name: 'HTML5'},
          { icon: <FontAwesomeIcon icon={faReact} color='#5ED4F4'/>, name: 'React'},
          { icon: <FontAwesomeIcon icon={faCss3} color='#28A4D9' />, name: 'CSS3'},
          { icon: <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />, name: 'Javascript'},
        ],
        link: 'https://quote-machine-8abc8.web.app/'
    },
    {
      image:
        PokedexJS,
        title: 'Pokédex',
        description: 'Ein Pokédex, der eine Übersicht aller Pokémon anzeigt',
        technologies: [
        
          { icon: <FontAwesomeIcon icon={faHtml5} color="#F06529" />, name: 'HTML5'},
          { icon: <FontAwesomeIcon icon={faCss3} color='#28A4D9' />, name: 'CSS3'},
          { icon: <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />, name: 'Javascript'},
        ],
        link: 'https://pokedex-js-f5f61.web.app/'
    },
    {
      image:
      PokedexReact,
        title: 'Pokédex React',
        description: 'Ein dynamischer Pokédex der alle Pokémon anzeigt, sowie alle Details zu diesen.',
        technologies: [
        
          { icon: <FontAwesomeIcon icon={faHtml5} color="#F06529" />, name: 'HTML5'},
          { icon: <FontAwesomeIcon icon={faReact} color='#5ED4F4'/>, name: 'React'},
          { icon: <FontAwesomeIcon icon={faCss3} color='#28A4D9' />, name: 'CSS3'},
          { icon: <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />, name: 'Javascript'},
          {
            icon: (
              <img
                src={Material}
                alt="Material UI"
                style={{ width: '20px', height: 'auto' }} 
              />
            ),
            name: 'MaterialUI', 
          },
          {
            icon: (
              <img
                src={Chart}
                alt="Chart JS"
                style={{ width: '20px', height: 'auto' }} 
              />
            ),
            name: 'Chart JS', 
          },
        
        ],
        link: 'https://pokedex-90da5.web.app/'
    },
  ];